<template>
  <v-container class="mt-10">
    <div class="authprev">
      <v-card class="pa-3">
        <h3 class="maincolor1--text">{{ $t("signup.name") }}</h3>
        <v-divider class="ma-3"></v-divider>
        <v-form ref="signup" v-model="validity">
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signup.form.username')"
              outlined
              v-model="username"
              dense
              :rules="usernameRules"
              clearable
              prepend-inner-icon="mdi-account-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signup.form.email')"
              outlined
              v-model="email"
              :rules="emailRules"
              dense
              clearable
              prepend-inner-icon="mdi-email-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-select
              :label="$t('signup.form.country')"
              outlined
              
              :items="countries"
              item-text="name"
              dense
              item-value="name"
              clearable
              v-model="country"
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signup.form.password')"
              outlined
              dense
              clearable
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              hint="At least 8 characters"
              counter
              prepend-inner-icon="mdi-key-outline"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0">
            <v-text-field
              :label="$t('signup.form.repeatPassword')"
              outlined
              dense
              clearable
              v-model="cpassword"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              hint="At least 8 characters"
              counter
              prepend-inner-icon="mdi-key-outline"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pa-0 ma-0 text-center">
            <v-btn
              class="col-md-12"
              dense
              
              color="success"
              @click="handleSubmit"
              :dark="!loading"
              elevation="3"
              :loading="loading"
            >
              <v-icon>mdi-plus-outline</v-icon> {{ $t("signup.name") }}
            </v-btn>
          </v-col>
        </v-form>
        <v-divider class="ma-3"></v-divider>
       
      </v-card>
         <v-card class="mt-3 pa-3 text-center maincolor2" shaped>
         <p class="white--text">{{ $t("signup.ifnot") }}</p>
        <router-link to="/login">
          <v-btn outlined color="white">
            <span >
            <v-icon>mdi-logout-variant</v-icon> {{ $t("signin.name") }}
          </span>
          </v-btn>
        </router-link>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import countries from "@/assets/countries";
export default {
  data() {
    return {
      countries,
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: "",
      cpassword: "",
      email: "",
      username: "",
      country: "",
      agree: false,
      validity: false,
      loading: false,
    };
  },
  computed: {
    usernameRules() {
      return [
        (v) => !!v || this.$t("signup.errors.urequired"),
        (v) =>
          this.$pattern.username.test(v) || this.$t("signup.errors.invalidu"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("signup.errors.erequired"),
        (v) => this.$pattern.email.test(v) || this.$t("signup.errors.invalide"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("signup.errors.prequired"),
        (v) =>
          this.$pattern.password.test(v) || this.$t("signup.errors.invalidp"),
      ];
    },
    valid() {
      return this.$refs.signup.validate();
    },
  },
  // watch: {
  //   "$i18n.locale"(newV, oldV) {
  //     this.$refs.signup.validate();
  //   },
  // },

  methods: {
    successNotification(text) {
      this.$notify({
        group: "success",
        type: "success",
        title: "success",
        text: text,
      });
    },
    errorNotification(text, type = "error") {
      this.$notify({
        group: "errors",
        type: type,
        title: "error",
        text: text,
      });
    },

    handleSubmit() {
      if (this.valid) {
        this.loading = true;
        if (this.password != this.cpassword) {
          this.loading = false;
          this.$swal.fire(
            this.$t("signup.errors.auth"),
            this.$t("signup.errors.invalidp"),
            "warning"
          );
        } else {
          this.$store
            .dispatch("signUp", {
              username_u: this.username,
              password: this.password,
              email: this.email,
              country: this.country,
              fcm: "null",
            })
            .then((resul) => {
              this.loading = false;
              // resul.status === 200 &&
              if (resul.data.code == 200) {
                this.$router.push({ name: "Login" });
                this.successNotification(this.$t("signup.success.registred"));
              } else {
                if (resul.data.message === "error create already username") {
                  this.$swal.fire(
                    this.$t("signup.errors.auth"),
                    this.$t("signup.errors.usernameAlreadyRegistred"),
                    "warning"
                  );
                } else if (resul.data.code === "505") {
                  this.$swal.fire(
                    this.$t("signup.errors.auth"),
                    this.$t("signup.errors.emailAlreadyRegistred"),
                    "warning"
                  );
                }

                // this.$swal.fire("error Sign up", resul.data.message, "warning");
              }
            })
            .catch((err) => {
              // alert("sjsjsh");
              // this.errorNotification(err.message);
              this.$swal.fire(
                this.$t("signup.errors.auth"),
                err.message,
                "warning"
              );
              this.loading = false;
            });
        }
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
